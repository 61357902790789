import React, {
  FunctionComponent,
  useState,
  ReactNode,
  ReactElement,
} from 'react';
import { usePopper } from 'react-popper';
import { Placement } from '@popperjs/core';
import { useIntl } from 'react-intl';
import { FCWithChildren } from '../lib/types';

type TooltipProps = {
  tip: ReactNode;
  children: ReactElement;
  name?: string;
  id?: string;
  placement?: Placement;
  offset?: [number, number];
  tipClassName?: string;
  triggerClassName?: string;
  containerClassNames?: string;
  dataTestID?: string;
  element?: HTMLElement | string;
  active?: boolean;
};

export const Tooltip: FunctionComponent<TooltipProps> = ({
  active = true,
  children,
  name,
  tip,
  id,
  placement = 'top',
  tipClassName = 'w-72',
  offset = [0, 10],
  triggerClassName = '',
  containerClassNames = 'inline',
  dataTestID,
}) => {
  const intl = useIntl();
  const [isOpen, setIsOpen] = useState(false);
  const [referenceElement, setReferenceElement] =
    useState<HTMLButtonElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
    null
  );
  const [arrowElement, setArrowElement] = useState<HTMLDivElement | null>(null);

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    modifiers: [
      {
        name: 'arrow',
        options: {
          element: arrowElement,
        },
      },
      {
        name: 'offset',
        options: {
          offset,
        },
      },
      {
        name: 'preventOverflow',
        options: {
          padding: 8,
        },
      },
    ],
    placement,
  });

  if (!active) {
    return children;
  }

  const popperPlacement = attributes.popper
    ? attributes.popper['data-popper-placement']
    : '';
  const arrowBottom = `-bottom-1`;
  const arrowTop = `-top-1`;
  const arrowPlacement =
    popperPlacement && popperPlacement.includes('top') ? arrowBottom : arrowTop;

  return (
    <span>
      <div className={`${containerClassNames}`}>
        <button
          type="button"
          ref={setReferenceElement}
          className={`whitespace-normal cursor-pointer ${triggerClassName}`}
          data-testid={dataTestID}
          name={name}
          tabIndex={0}
          aria-label={intl.formatMessage(
            {
              defaultMessage:
                '{hasName, select, true {Click here for more information on {name}} other {Click here for more information}} ',
              description: 'Tooltip description',
            },
            {
              name: name,
              hasName: true,
            }
          )}
          onMouseDown={() => {
            setIsOpen(!isOpen);
          }}
          onFocus={() => {
            !isOpen && setIsOpen(true);
          }}
          onBlur={() => {
            isOpen && setIsOpen(false);
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter' || e.key === ' ') {
              e.preventDefault();
              setIsOpen(!isOpen);
            } else if (e.key === 'Escape') {
              isOpen && setIsOpen(false);
            }
          }}
        >
          {children}
        </button>

        {isOpen && (
          <div
            role="tooltip"
            id={id}
            ref={setPopperElement}
            style={styles.popper}
            {...attributes.popper}
            className={`whitespace-normal rounded bg-white shadow-tooltip text-blackberry p-5 z-[60] ${tipClassName}`}
          >
            {tip}
            <div
              ref={setArrowElement}
              style={styles.arrow}
              {...attributes.popper}
              className={arrowPlacement}
            >
              <div className="transform -rotate-45 w-2.5 h-2.5 bg-white p-3 border-solid border-transparent"></div>
            </div>
          </div>
        )}
      </div>
    </span>
  );
};

export const TooltipHeading: FCWithChildren = ({ children }) => (
  <h3 className="text-base text-left mb-2 font-bold">{children}</h3>
);

export const TooltipParagraph: FCWithChildren = ({ children }) => (
  <p className="text-sm text-left font-normal leading-5">{children}</p>
);
